import { amgApi } from "@/service/axios";

class ClientSpecialistService {
  async searchClientSp(params) {
    try {
      const data = await amgApi.post("/specialists/search-client-sp", params);
      return data.data;
    } catch (error) {
      console.log("Error searchClientSp");
    }
  }
  async searchClientSpOld(params) {
    try {
      const data = await amgApi.post("/specialists/search-client-sp", params);
      return data.data;
    } catch (error) {
      console.log("Error searchClientSp");
    }
  }
  async searchClientShared(params) {
    try {
      const data = await amgApi.post(
        "/specialists/search-client-shared",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error searchClientSp");
    }
  }
  async searchClientOfProgram(params) {
    try {
      const data = await amgApi.post(
        "/specialists/search-client-of-program",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error searchClientOfProgram");
    }
  }

  async getAllSupervisorPrograms(params) {
    try {
      const data = await amgApi.post(
        "specialists/get-all-supervisor-programs",
        params
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getAllSupervisorPrograms:", error);
      throw error;
    }
  }
}

export default new ClientSpecialistService();
