export default [
  {
    key: "selected",
    label: "Name",
    class: "text-left",
    visible: true,
  },
  {
    key: "lead_name",
    label: "Client",
    thClass: "pt-1",
    sortable: false,
    visible: true,
    thStyle: { width: "250px" },
  },
  {
    key: "users_comp",
    label: "Shared Users",
  },
  {
    key: "mobile",
    label: "Mobile",
  },
  {
    key: "account",
    label: "Account",
  },
  {
    key: "program",
    label: "Program",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "charges",
    label: "Fee & Charges",
  },
  {
    key: "monthly_amount",
    label: "Monthly Payment",
  },
  {
    key: "last_payment",
    label: "Last Payment",
  },
  {
    key: "pt",
    label: "PT",
  },
  {
    key: "advisor_name",
    label: "Advisor",
  },
  {
    key: "created_at",
    label: "Creation Date",
  },
  {
    key: "services",
    label: "Services",
  },
  {
    key: "actions",
    label: "Actions",
  },
];
